
import { defineComponent, inject, onMounted, ref} from "vue";
import { useRouter, useRoute } from "vue-router";
import $ from "jquery";
import axios from "@/api/axios";
import qs from "qs";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import * as echarts from 'echarts';
import { parthrottle } from '@/utils/utils'
export default defineComponent({
  name: "VoteView",
  components: {
    Loading
  },
  setup() {
    const satisfieNum = ref(0)
    const satisfiePercent = ref('0%')
    const disatisfieNum = ref(0)
    const disatisfiePercent = ref('0%')

    const yearSelected = ref('')
    const monthSelected = ref('')
    const page = ref(1)
    const isEnd = ref(false)
    const totalNum = ref()

    const voteList = ref<object>([])
    const router = useRouter();
    const route = useRoute();
    const pie = ref()
    //  展示用户信息
    const userInfo = inject("userInfo") as any;

    const loading = ref(false);
    const loadOption = {
      text: "正在加载...",
      color: "#df0024",
      textColor: "#df0024",
    };

    onMounted(function() {
      if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          window.location.href = 'https://www.bzwz.com/User/CanteenVotingStatistics.html'
      }

      $('.home-wapper').css('padding-top', '0')
      const height = Number($(window).height()) - Number($('header').outerHeight())-Number($('main').outerHeight()) + 'px'
      $('.vote-list').css({'height': height})

      const token = localStorage.getItem("token");
      if (!token) {
          Modal({
              title: "温馨提示",
              content: "登录后才可以进行投票！",
              onClose: () => {
                  router.push({
                      name: "Login",
                      query: {
                          redirect: router.currentRoute.value.fullPath,
                      },
                  });
              },
          });
          return false;
      }

      loading.value = true;
      axios
      .get("/M/UserServer/canteenVotingStatistics")
      .then(res => {
        loading.value = false;
        if (res.data.success) {
          const data = res.data.obj;
          totalNum.value = data.isSatisfactionCount + data.noSatisfactionCount
          satisfieNum.value = data.isSatisfactionCount
          disatisfieNum.value = data.noSatisfactionCount
          satisfiePercent.value = data.isSatisfactionCountPercent
          disatisfiePercent.value = data.noSatisfactionCountPercent
          voteList.value = (voteList.value as any).concat(data.canteenVotingRecordList)
          yearSelected.value = data.year
          monthSelected.value = data.month

          const myChart = echarts.init(pie.value);
          myChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: "数量 : {c} <br/>{b} : {d}%"
            },
            legend: {
              bottom: 10,
              orient: 'vertical',
              right: 'right',
              data: ['满意', '不满意']
            },
            series: [
              {
                name: '餐厅投票',
                type: 'pie',
                radius: '90%',
                label:{            //饼图图形上的文本标签
                  normal:{
                    show: true,
                    position:'inner', //标签的位置
                    textStyle : {
                        fontWeight : 300 ,
                        fontSize : 14,    //文字的字体大小
                        color: '#fff'
                    },
                    formatter:'{d}%'
                  }
                },
                data: [
                  { 
                    value: data.isSatisfactionCount, 
                    name: '满意', 
                    itemStyle:{
                        normal:{color:'#d64b4b'}
                      } 
                    },
                  { 
                    value: data.noSatisfactionCount,  
                    name: '不满意',
                    itemStyle:{
                        normal:{color:'#7AB7F5'}
                      } 
                  }
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          });
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
            onClose: () => {
                router.push({
                  name: "VoteTest"
                });
              },
          });
        }
      
      })
      .catch(err => {
        console.log(err);
      });
    })

    function queryVote() {
      const data = qs.stringify({
        year: yearSelected.value,
        month: monthSelected.value
      });

      axios
        .post("/M/UserServer/canteenVotingStatistics", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj;
            satisfieNum.value = data.isSatisfactionCount
            disatisfieNum.value = data.noSatisfactionCount
            totalNum.value = data.isSatisfactionCount + data.noSatisfactionCount
            satisfiePercent.value = data.isSatisfactionCountPercent
            disatisfiePercent.value = data.noSatisfactionCountPercent
            voteList.value = data.canteenVotingRecordList
            yearSelected.value = data.year
            monthSelected.value = data.month
            const myChart = echarts.init(pie.value);
            myChart.setOption({
              tooltip: {
                trigger: 'item',
                formatter: "数量 : {c} <br/>{b} : {d}%"
              },
              legend: {
                bottom: 10,
                orient: 'vertical',
                right: 'right',
                data: ['满意', '不满意']
              },
              series: [
                {
                  name: '餐厅投票',
                  type: 'pie',
                  radius: '90%',
                  label:{            //饼图图形上的文本标签
                    normal:{
                      show: true,
                      position:'inner', //标签的位置
                      textStyle : {
                          fontWeight : 300 ,
                          fontSize : 14,    //文字的字体大小
                          color: '#fff'
                      },
                      formatter:'{d}%'
                    }
                  },
                  data: [
                    { 
                      value: data.isSatisfactionCount, 
                      name: '满意', 
                      itemStyle:{
                          normal:{color:'#d64b4b'}
                        } 
                      },
                    { 
                      value: data.noSatisfactionCount,  
                      name: '不满意',
                      itemStyle:{
                          normal:{color:'#7AB7F5'}
                        } 
                    }
                  ],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                }
              ]
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err)
        });

    }

    const scrollEvent = parthrottle(function(e) {
      if (e.srcElement.scrollTop + e.srcElement.offsetHeight + 200 >= e.srcElement.scrollHeight) {
          if(isEnd.value) {
              Toast({
                  type: "error",
                  title: '已经到底了！',
                });
              return false
          }
          loading.value = true;
          page.value++
          const data = qs.stringify({
            page: page.value
          });

          axios
          .post("/M/UserServer/canteenVotingRecordListMore", data, {
            params: {
              validate: true,
            },
          })
          .then((res) => {
            loading.value = false;
            if (res.data.success) {
              const data = res.data.obj;
              if (data.length>0) {
                voteList.value = (voteList.value as any).concat(data)
              } else {
                page.value--
                isEnd.value = true
                Toast({
                  type: "error",
                  title: '已经到底了！',
                });
              }
              
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          })
          .catch((err) => {
            console.log(err)
          });
        }
    }, 400)


    // function scrollEvent(e: any) {
    //   if (e.srcElement.scrollTop + e.srcElement.offsetHeight >= e.srcElement.scrollHeight) {
    //     if(isEnd.value) {
    //         Toast({
    //             type: "error",
    //             title: '已经到底了！',
    //           });
    //         return false
    //     }
    //     loading.value = true;
    //     page.value++
    //     const data = qs.stringify({
    //       page: page.value
    //     });

    //      axios
    //     .post("/M/UserServer/canteenVotingRecordListMore", data, {
    //       params: {
    //         validate: true,
    //       },
    //     })
    //     .then((res) => {
    //       loading.value = false;
    //       if (res.data.success) {
    //         const data = res.data.obj;
    //         if (data.length>0) {
    //           voteList.value = voteList.value.concat(data)
    //         } else {
    //           page.value--
    //           isEnd.value = true
    //           Toast({
    //             type: "error",
    //             title: '已经到底了！',
    //           });
    //         }
            
    //       } else {
    //         Toast({
    //           type: "error",
    //           title: res.data.msg,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     });
    //   }
    // }

    function back() {
      router.back();
    }

    function onStar(id: any, index: any) {
      const data = qs.stringify({
        id
      });
      loading.value = true;
      axios
      .post("/M/UserServer/doCanteenVotingRecordLike", data, {
        params: {
          validate: true
        }
      })
      .then(res => {
        loading.value = false;
        if (res.data.success) {
          if(voteList.value[index].flag) {
            voteList.value[index].likeCount -=1
            voteList.value[index].flag = false
          } else {
            voteList.value[index].likeCount +=1
            voteList.value[index].flag = true
          }
        }
      })

    }

    return {
      userInfo,
      satisfieNum,
      disatisfieNum,
      satisfiePercent,
      disatisfiePercent,
      voteList,
      yearSelected,
      monthSelected,
      queryVote,
      scrollEvent,
      loading,
      loadOption,
      back,
      pie,
      totalNum,
      onStar
    }
  },
});
